import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { openModal } from '../../templates/Challenges/redux/actions';
import { checkUserRegistration } from '../../redux/actions';
import { SuperBlocks } from '../../../../config/certification-settings';
import {
  completedChallengesSelector,
  courseCodeInfoSelector,
  userRegistrationStatusSelector,
  userSelector
} from '../../redux/selectors';
import {
  ChallengeNode,
  CompletedChallenge,
  CourseCodeDataProps,
  User
} from '../../redux/prop-types';
import { RegistrationResponseProps } from '../../utils/ajax';
import LearnMap from './components/learn-map';

interface MapTrailProps {
  readonly currentSuperBlock?: SuperBlocks | null;
  readonly completedChallengeIds: string[];
  readonly access: string;
  readonly user: User;
  readonly userRegistrationStatus: RegistrationResponseProps;
  readonly courseCodeInfo: CourseCodeDataProps;
  readonly checkUserRegistration: () => void;
}

interface MapData {
  modules: {
    nodes: ChallengeNode[];
  };
  phases: {
    nodes: ChallengeNode[];
  };
}

const mapStateToProps = createSelector(
  completedChallengesSelector,
  userRegistrationStatusSelector,
  userSelector,
  courseCodeInfoSelector,
  (
    completedChallenges: CompletedChallenge[],
    userRegistrationStatus: RegistrationResponseProps,
    user: User,
    courseCodeInfo: CourseCodeDataProps
  ) => ({
    completedChallengeIds: completedChallenges.map(({ id }) => id),
    userRegistrationStatus,
    user,
    courseCodeInfo
  })
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      openCtaRegistrationModal: () => openModal('nacionalCertificate'),
      checkUserRegistration
    },
    dispatch
  );

export function MapTrail({
  currentSuperBlock = null,
  completedChallengeIds,
  access,
  user,
  userRegistrationStatus,
  courseCodeInfo,
  checkUserRegistration
}: MapTrailProps): React.ReactElement {
  const data: MapData = useStaticQuery(graphql`
    query SuperBlockNodes {
      modules: allChallengeNode(sort: { fields: [challenge___superOrder] }) {
        nodes {
          challenge {
            superBlock
            dashedName
            superOrder
          }
        }
      }
      phases: allChallengeNode(
        sort: { fields: [challenge___superOrder, challenge___phaseOrder] }
      ) {
        nodes {
          challenge {
            id
            superBlock
            phaseOrder
          }
        }
      }
    }
  `);

  const getFilteredNodes = (nodes: ChallengeNode[], user: User) => {
    const hasAccessToMundoTech =
      user.source.includes('nacional') || user.source.includes('piloto');
    if (
      user.isFundamentosDaProgramacaoFrontEndCert === true &&
      user.registrationQuiz == 1 &&
      hasAccessToMundoTech
    ) {
      return nodes;
    }
    if (user.registrationQuiz == 1 && hasAccessToMundoTech) {
      return nodes.filter(
        node =>
          node.challenge.superBlock ===
          SuperBlocks.FundamentoDaProgramacaoFrontEnd
      );
    }
    return nodes.filter(
      node =>
        node.challenge.superBlock !==
        SuperBlocks.FundamentoDaProgramacaoFrontEnd
    );
  };

  const getFilteredPhases = (phases: ChallengeNode[], user: User) => {
    const hasAccessToMundoTech =
      user.source.includes('nacional') || user.source.includes('piloto');

    if (
      user.isFundamentosDaProgramacaoFrontEndCert === true &&
      user.registrationQuiz == 1 &&
      hasAccessToMundoTech
    ) {
      return phases;
    }
    if (user.registrationQuiz == 1 && hasAccessToMundoTech) {
      return phases.filter(
        node =>
          node.challenge.superBlock ===
          SuperBlocks.FundamentoDaProgramacaoFrontEnd
      );
    }
    return phases.filter(
      node =>
        node.challenge.superBlock !==
        SuperBlocks.FundamentoDaProgramacaoFrontEnd
    );
  };

  const nodes = getFilteredNodes(data.modules.nodes, user);
  const phases = getFilteredPhases(data.phases.nodes, user);

  const uniqueSuperBlocks: unknown[] = [];

  const sorteredNodes = nodes.filter(element => {
    const isDuplicate = uniqueSuperBlocks.includes(
      element.challenge.superBlock
    );

    if (!isDuplicate) {
      uniqueSuperBlocks.push(element.challenge.superBlock);
      return true;
    } else {
      return false;
    }
  });

  return (
    <div className='map-ui' data-test-label='learn-curriculum-map'>
      <LearnMap
        nodes={sorteredNodes}
        phases={phases}
        completedChallengeIds={completedChallengeIds}
        currentSuperBlock={currentSuperBlock}
        access={access}
        cpf={user.cpf}
        courseCode={courseCodeInfo?.courseCodes?.[1] ?? 0}
        userRegistrationStatus={userRegistrationStatus}
        checkUserRegistration={checkUserRegistration}
        registrationQuiz={user.registrationQuiz}
        isFPFCompleted={user.isFundamentosDaProgramacaoFrontEndCert}
      />
    </div>
  );
}

MapTrail.displayName = 'MapTrail';

export default connect(mapStateToProps, mapDispatchToProps)(MapTrail);
